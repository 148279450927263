h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
}

* {
  font-family: "Urbanist", sans-serif;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  letter-spacing: 1px;
}
