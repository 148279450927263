@use "shared/scss" as *;

.notification-card {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 19;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: $gapS;

  &--toast {
    position: relative;
    background-color: $white;
    color: $black;
    width: 250px;
    padding: 10px 35px 10px 20px;
    border-radius: $borderRadius;

    @include laptop {
      width: 400px;
    }

    span {
      display: block;
      padding-left: 18px;
      margin-top: 2px;

      &:nth-child(2) {
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
  }

  &--toast-close {
    position: absolute;
    top: 20px;
    transform: translate(0, -50%);
    right: 10px;
    cursor: pointer;

    path {
      fill: $primary;
    }
  }

  &--toast-icon {
    position: absolute;
    top: 20px;
    transform: translate(0, -50%);
    left: 10px;

    path {
      fill: $black;
    }
  }
}
