// COLORS
$primary: #38a576;
$primaryLight: #3987ff;
$primaryLighter: rgba(232, 241, 255, 0.1);
$primaryDark: #28855d;

$success: #44d600;
$successLight: #51fd00;
$successLighter: rgba(68, 214, 0, 0.1);
$successDark: #3bb900;

$warning: #ffa31a;
$warningLight: #ffaf39;
$warningLighter: rgba(255, 246, 232, 0.1);
$warningDark: #f49200;

$error: #ff1a43;
$errorLight: #ff395c;
$errorLighter: rgba(255, 25, 67, 0.1);
$errorDark: #f4002c;

$info: #33c2ff;
$infoLight: #4ecaff;
$infoLighter: rgba(235, 249, 255, 0.1);
$infoDark: #0ab6ff;

$white: #f2f2f2;
$black: #222d35;
$background: #131c23;
$dark: #050505;

// GRADIENTS
$gradient1: linear-gradient(135deg, #6b73ff 0%, #000dff 100%);
$gradient2: linear-gradient(135deg, #abdcff 0%, #0396ff 100%);
$gradient3: linear-gradient(127.55deg, #141e30 3.73%, #243b55 92.26%);
$gradient4: linear-gradient(135deg, #fccf31 0%, #f55555 100%);
$gradient5: linear-gradient(135deg, #ffd3a5 0%, #fd6585 100%);
$gradient6: linear-gradient(135deg, #43cbff 0%, #9708cc 100%);
$gradient7: linear-gradient(135deg, #f6ceec 0%, #d939cd 100%);
$gradient8: linear-gradient(135deg, #f761a1 0%, #8c1bab 100%);
$gradient9: linear-gradient(135deg, #fff720 0%, #3cd500 100%);
$gradient10: linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%);

// BREAKPOINTS
$mobile: 425px;
$tablet: 768px;
$laptop: 1200px;
$desktop: 1440px;

//FONT-FAMILY
$nunito: "Nunito", sans-serif;

// STRUCTURE
$borderRadius: 6px;
$padding: 20px;
$gapS: 16px;
$gapL: 26px;
