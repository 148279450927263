@use "shared/scss" as *;

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    width: 100%;
    height: 100%;
    max-width: 70px;
    max-height: 70px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  > h3 {
    color: $white;
    font-size: 30px;
    width: fit-content;
  }
}
